<template>
  <div>
    <div class="section section_1">
      <div class="content">
        <div class="title">
          <p>随时随地<br />掌握有关店铺的一切</p>
          <p>店教授·我的店铺</p>
          <p>手机看店新操作 省钱省力省时间</p>
        </div>
        <a class="btn" href="http://pos.yunlankeji.com/" target="_blank"></a>
      </div>
    </div>

    <div class="section section_2">
      <div class="content">
        <div class="little-title"></div>
        <div class="title">你是否面临如下困惑</div>
        <div class="confusion">
          <p>每天都在店，完全没有自己的时间</p>
          <p>七八个店面上百公里，为了监督总要在一天内跑完</p>
          <p>账货不匹配，总是对不上错在哪里</p>
          <p>报表一大堆，费时费力还找不到关键数据</p>
        </div>
      </div>
    </div>

    <div class="section section_3">
      <div class="content">
        <div class="little-title"></div>
        <div class="title">我的店铺 全方位解决方案</div>
        <div class="flex">
          <div class="item">告别人力时间虚耗</div>
          <div class="line"></div>
          <div class="item">随时随地高效监督</div>
          <div class="line"></div>
          <div class="item">不用守店，信息全掌控</div>
        </div>
      </div>
    </div>

    <div class="section section_4">
      <div class="content">
        <div class="sub-title">
          除了今天卖了多少钱,<br />你一定还想知道更更更更多。
        </div>
        <div class="flex">
          <div class="img"></div>
          <div class="description">
            <p>销售模块</p>
            <p>
              卖了啥？啥卖得好？<br />卖了多少单？有没有退货？都怎么付款的？
            </p>
            <p>
              一秒锁定畅销款<br />各类销售占比一目了然，<br />甚至连利润都帮你算好。
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section section_5">
      <div class="content">
        <div class="sub-title">即使会员成千上万,<br />你却仍可如数家珍。</div>
        <div class="flex">
          <div class="description">
            <p>会员模块</p>
            <p>
              各等级会员、新增会员有多少？<br />今日几人进店消费？会员的消费分布情况？
            </p>
            <p>掌握了会员消费习惯，<br />就掌握了让会员生钱的诀窍。</p>
          </div>
          <div class="img"></div>
        </div>
      </div>
    </div>

    <div class="section section_6">
      <div class="content">
        <div class="sub-title">营销活动效果如何,<br />看这一页就全明白了。</div>
        <div class="flex">
          <div class="img"></div>
          <div class="description">
            <p>营销模块</p>
            <p>
              各门店都有什么活动？今日核销了多少卡券？<br />拼团/团购/砍价有多少人参与？……
            </p>
            <p>锁定顾客钟爱的营销活动，<br />让一次促销带来更多消费可能。</p>
          </div>
        </div>
      </div>
    </div>

    <div class="section section_7">
      <div class="content">
        <div class="little-title"></div>
        <div class="title">繁杂琐事 一键搞定</div>
        <div class="flex">
          <div class="item highlight">
            <p>库存预警</p>
          </div>
          <div class="item">
            <p>线上订货</p>
          </div>
          <div class="item">
            <p>活动通知</p>
          </div>
          <div class="item">
            <p>网店概览</p>
          </div>
          <div class="item">
            <p>门店监控</p>
          </div>
          <div class="item">
            <p>重要事项短信提醒</p>
          </div>
        </div>
        <div class="store-icon"></div>
        <div class="footer-text">
          <p>店教授·我的店铺APP</p>
          <p>摆脱店铺束缚<br />实现躺着数钱做老板的梦想</p>
        </div>
        <!-- <div class="qr-code" id="qr-code">
          <div class="android"></div>
          <div class="ios"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import '../../../assets/css/cloud/common.css'
import '../../../assets/css/common/common.css'
import '../../../assets/css/common/case.css'
export default {};
</script>

<style scoped>
.section .content {
  margin: 0;
}
.little-title {
  height: 14px;
}
.title {
  font-size: 40px;
  color: #252d37;
  text-align: center;
  line-height: 56px;
  font-weight: 600;
}
.sub-title {
  font-size: 36px;
  color: #1193fe;
  text-align: center;
  line-height: 44px;
  font-weight: 600;
  padding: 70px 0 55px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.description p:nth-of-type(1) {
  font-size: 32px;
  line-height: 45px;
  color: #252d37;
  margin: 20px 0 10px;
}
.description p:nth-of-type(2) {
  font-size: 16px;
  color: #687178;
  line-height: 22px;
  margin-bottom: 26px;
}
.section {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  font-family: PingFang-SC-Bold;
  font-size: 19px;
  color: #344154;
  letter-spacing: 0;
  line-height: 28px;
}
/* .section_2,
.section_3,
.section_4,
.section_5,
.section_6,
.section_7 {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition: opacity, transform 1s linear;
} */
.section_3,
.section_4,
.section_5,
.section_6,
.section_7 {
  background-color: #f6f7fb;
}
.section_1 {
  height: 699px;
  background-image: url("../../../assets/images/product/pcStore/section_1.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* background: url("../../../assets/images/product/pcStore/section_1.png") no-repeat top center #f6f7fb; */
}
.section_1 .title p {
  color: #fff;
  text-align: left;
  padding-left: 600px;
}
.section_1 .title p:nth-of-type(1) {
  font-size: 50px;
  line-height: 57px;
  padding-top: 205px;
}
.section_1 .title p:nth-of-type(2) {
  font-size: 22px;
  line-height: 36px;
  padding-top: 36px;
}
.section_1 .title p:nth-of-type(3) {
  font-size: 18px;
  line-height: 25px;
  padding-top: 4px;
  font-weight: 100;
}
.section_1 .btn {
  display: block;
  width: 226px;
  height: 60px;
  margin: 44px 600px;
  background: url("../../../assets/images/product/pcStore/btn.png") no-repeat center transparent;
}
.section_2 {
  height: 475px;
  background: url("../../../assets/images/product/pcStore/section_2.png") no-repeat bottom center #f6f7fb;
  background-position: bottom;
  background-size: auto;
}
.section_2 .little-title {
  margin-top: 100px;
  background: url("../../../assets/images/product/pcStore/01-FacingTheConfus.png") no-repeat top center #f6f7fb;
}
.section_2 .confusion {
  height: 375px;
}
.section_2 .confusion p:nth-of-type(1) {
  padding: 110px 0 0 265px;
}
.section_2 .confusion p:nth-of-type(2) {
  padding: 44px 0 0 522px;
}
.section_2 .confusion p:nth-of-type(3) {
  padding: 45px 0 0 180px;
}
.section_2 .confusion p:nth-of-type(4) {
  padding-left: 730px;
  transform: translateY(-29px);
}
.section_3 {
  background: #f6f7fb;
}
.section_3 .little-title {
  margin-top: 168px;
  background: url("../../../assets/images/product/pcStore/02-TheSolution.png") no-repeat top center #f6f7fb;
}
.section_3 .line {
  width: 193px;
  height: 0;
  border: dashed #d7d9e2;
  border-top: 4px;
  margin: 133px 12px 0 61px;
}
.section_3 .item {
  width: 202px;
  height: 168px;
  margin: 82px 0 114px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 20px;
  color: #344154;
  font-weight: 600;
}
.section_3 .item:nth-of-type(1) {
  background: url("../../../assets/images/product/pcStore/icon01.png") no-repeat left top #f6f7fb;
}
.section_3 .item:nth-of-type(3) {
  background: url("../../../assets/images/product/pcStore/icon02.png") no-repeat left top #f6f7fb;
}
.section_3 .item:nth-of-type(5) {
  background: url("../../../assets/images/product/pcStore/icon03.png") no-repeat left top #f6f7fb;
}
.section_5 {
  background: #fff;
}
.section_4 .img,
.section_6 .img {
  width: 580px;
  margin-right: 88px;
  margin-bottom: 60px;
}
.section_4 .img {
  height: 257px;
  background: url("../../../assets/images/product/pcStore/img01.png") no-repeat top center #f6f7fb;
}
.section_5 .img {
  width: 747px;
  height: 257px;
  margin-left: 70px;
  margin-bottom: 60px;
  background: url("../../../assets/images/product/pcStore/img02.png") no-repeat top center #f6f7fb;
}
.section_6 .img {
  height: 275px;
  background: url("../../../assets/images/product/pcStore/img03.png") no-repeat top center #f6f7fb;
}
.section_6 .description p:nth-of-type(1) {
  margin-top: 30px;
}
.section_7 {
  height: 1100px;
  background: url("../../../assets/images/product/pcStore/background-cut.png") no-repeat bottom center #f6f7fb;
}
.section_7 .little-title {
  margin-top: 116px;
  background: url("../../../assets/images/product/pcStore/03-intelligenceFunc.png") no-repeat top center #f6f7fb;
}
.section_7 .title {
  margin-bottom: 68px;
}
.section_7 .flex {
  justify-content: flex-end;
}
.section_7 .item:not(.highlight) {
  width: 265px;
  font-size: 20px;
  color: #344154;
  border-radius: 6px;
}
.section_7 .item:not(.highlight) p {
  margin: 103px 0 0 50px;
  width: max-content;
}
.section_7 .item {
  width: 356px;
  height: 219px;
  font-size: 20px;
  color: #fff;
  margin: 0 7px;
  transition: width 0.1s linear;
}
.section_7 .item p {
  margin: 103px 0 0 83px;
  width: max-content;
}
.section_7 .item:nth-of-type(1) {
  margin-left: 0;
}
.section_7 .item:nth-of-type(5) {
  margin-left: 555px;
}
.section_7 .item:not(.highlight):nth-of-type(1) {
  background: url("../../../assets/images/product/pcStore/1.png") no-repeat center transparent;
}
.section_7 .item:not(.highlight):nth-of-type(2) {
  background: url("../../../assets/images/product/pcStore/2.png") no-repeat center transparent;
}
.section_7 .item:not(.highlight):nth-of-type(3) {
  background: url("../../../assets/images/product/pcStore/3.png") no-repeat center transparent;
}
.section_7 .item:not(.highlight):nth-of-type(4) {
  background: url("../../../assets/images/product/pcStore/4.png") no-repeat center transparent;
}
.section_7 .item:not(.highlight):nth-of-type(5) {
  background: url("../../../assets/images/product/pcStore/5.png") no-repeat center transparent;
}
.section_7 .item:not(.highlight):nth-of-type(6) {
  background: url("../../../assets/images/product/pcStore/6.png") no-repeat center transparent;
}
.section_7 .item:nth-of-type(1) {
  background: url("../../../assets/images/product/pcStore/1-highlight.png") no-repeat center transparent;
}
.section_7 .item:nth-of-type(2) {
  background: url("../../../assets/images/product/pcStore/2-highlight.png") no-repeat center transparent;
}
.section_7 .item:nth-of-type(3) {
  background: url("../../../assets/images/product/pcStore/3-highlight.png") no-repeat center transparent;
}
.section_7 .item:nth-of-type(4) {
  background: url("../../../assets/images/product/pcStore/4-highlight.png") no-repeat center transparent;
}
.section_7 .item:nth-of-type(5) {
  background: url("../../../assets/images/product/pcStore/5-highlight.png") no-repeat center transparent;
}
.section_7 .item:nth-of-type(6) {
  background: url("../../../assets/images/product/pcStore/6-highlight.png") no-repeat center transparent;
}
.section_7 .store-icon {
  width: 127px;
  height: 126px;
  background: url("../../../assets/images/product/pcStore/store.png") no-repeat center transparent;
  margin: 55px 0 0 120px;
}
.section_7 .footer-text p:nth-of-type(1) {
  font-size: 44px;
  line-height: 50px;
  font-weight: 600;
  margin: 35px 0 0 120px;
}
.section_7 .footer-text p:nth-of-type(2) {
  color: #687178;
  transform: translate(660px, -60px);
}
.section_7 .qr-code {
  display: flex;
}
.section_7 .qr-code div {
  width: 375px;
  height: 109px;
}
.section_7 .ios {
  margin-left: 150px;
  background: url("../../../assets/images/product/pcStore/ios.png") no-repeat center transparent;
}
.section_7 .android {
  margin-left: 120px;
  background: url("../../../assets/images/product/pcStore/android.png") no-repeat center transparent;
}
</style>